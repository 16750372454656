.not-found {
  max-width: 560px;
  margin: 100px auto;
  text-align: center;
  .ant-typography:first-child {
    font-size: 80px;
    font-weight: 500;
  }
  .ant-typography:nth-child(2) {
    font-size: 20px;
    font-weight: 600;
  }
  .ant-typography:nth-child(3) {
    font-size: 18px;
    color: #4d5562;
    margin: 24px 0;
  }
}

@primary-color: #F7941D;@link-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #DD1616;@font-size-base: 14px;@font-weight: 400;@heading-color: rgba(0, 0, 0, 1);@text-color: rgba(0, 0, 0, 1);@text-color-secondary: #5E5C62;@disabled-color: #AAAAAA;@border-radius-base: 5px;@border-color-base: #d9d9d9;@box-shadow-base: 0 2px 8px rgba(0, 0, 0, 0.15);