.modal__content--reason {
  .ant-form-item-explain-connected {
    margin-bottom: 24px;
    margin-top: 5px;
  }
}
.ant-modal-content {
  border-radius: 4px;
  .ant-modal-close {
    right: 5px !important;
  }
  .modal__actions {
    display: flex;
    align-items: center;
    justify-content: center;
    // gap: 16px;
    &--cancel {
      background-color: var(--bg-color-mono-4);
      border: none;
      padding: 8px 47px;
      color: var(--color-body);
      &:hover {
        color: var(--color-body);
      }
    }
  }
  .ant-modal-body {
    padding: 16px 24px 32px 24px;
  }
}
.ant-modal-footer {
  padding: 29px 29px 30px 30px;
}
