.pagination-position-end {
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.pagination-position-start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.pagination-position-center {
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-pagination-simple-pager input {
  padding: 0px !important;
  width: 35px;
  height: 35px;
}

.ant-pagination-simple-pager {
  height: 30px !important;
  margin-right: 0px !important;
}

.ant-pagination {
  display: flex;
  align-items: center;
}
.ant-pagination-prev {
  margin-right: 0 !important;
}
.ant-pagination-disabled {
  color: var(--bg-color-mono-3);
}
.ant-pagination-item {
  border: none !important;
  margin-right: 0 !important;
}
.ant-pagination-item-link {
  border: none !important;
}
.ant-pagination-disabled {
  button[type="button"]:disabled {
    background-color: transparent !important;
  }
}

@primary-color: #F7941D;@link-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #DD1616;@font-size-base: 14px;@font-weight: 400;@heading-color: rgba(0, 0, 0, 1);@text-color: rgba(0, 0, 0, 1);@text-color-secondary: #5E5C62;@disabled-color: #AAAAAA;@border-radius-base: 5px;@border-color-base: #d9d9d9;@box-shadow-base: 0 2px 8px rgba(0, 0, 0, 0.15);