.maintenance {
  text-align: center;

  max-width: 516px;
  margin: 100px auto;
  height: 100%;
  &__title {
    padding: 24px 0;
    font-size: 24px;
    font-weight: 600;
  }
  &__sub {
    font-size: 14px;
    color: #4d5562;
  }
}

@primary-color: #F7941D;@link-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #DD1616;@font-size-base: 14px;@font-weight: 400;@heading-color: rgba(0, 0, 0, 1);@text-color: rgba(0, 0, 0, 1);@text-color-secondary: #5E5C62;@disabled-color: #AAAAAA;@border-radius-base: 5px;@border-color-base: #d9d9d9;@box-shadow-base: 0 2px 8px rgba(0, 0, 0, 0.15);