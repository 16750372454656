.title {
  .typography(var(--color-body) , var(--font-title-1-size), var(--font-body-medium-weight));
}

.text-default {
  .typography();
}
.title-modal {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  padding-top: 15px;
}
