.modal__otp {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  &-error {
    color: var(--color-text-error) !important;
    font-weight: var(--font-body-regular-weight);
  }
  .title-modal {
    margin-bottom: 20px;
  }
  .ant-typography:nth-child(2) {
    margin-bottom: 20px;
  }
}

@primary-color: #F7941D;@link-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #DD1616;@font-size-base: 14px;@font-weight: 400;@heading-color: rgba(0, 0, 0, 1);@text-color: rgba(0, 0, 0, 1);@text-color-secondary: #5E5C62;@disabled-color: #AAAAAA;@border-radius-base: 5px;@border-color-base: #d9d9d9;@box-shadow-base: 0 2px 8px rgba(0, 0, 0, 0.15);