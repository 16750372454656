.ant-table {
  background: transparent;
  font-size: var(--font-body-medium-size);

  .ant-table-thead {
    background-color: #f2f2f2;
    tr {
      th {
        font-size: var(--font-small-body-medium-size);
        padding: 16px;
        background: transparent;
        color: var(--color-body);
        font-weight: var(--font-small-body-semibold-weight);
        border-bottom: 1px solid var(--bg-color-mono-4);
      }
    }
  }

  .ant-table-tbody {
    td {
      font-size: var(--font-small-body-bold-size);
      padding: 16px;
      transition: text-shadow 0.3s;
      color: var(--color-body);
      border-bottom: 1px solid var(--bg-color-mono-4);
      word-wrap: break-word;
    }
  }

  .stt {
    width: 1%;
    white-space: nowrap;
  }

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
  }

  .ant-table-column-title {
    flex: none;
  }

  .ant-table-filter-trigger {
    display: inline-block;
  }

  .ant-table-filter-column {
    display: block;
  }
}
