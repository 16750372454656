.backdrop {
    top: 0;
    left: 0;
    z-index: 99;
    position: absolute;

    width: 100%;
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 36px;
    color: var(--color-dard-orange);
    background-color: rgba(223, 223, 233, 0.368);

    animation: animated 0.5s ease-in-out;
}

@keyframes animated {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
@primary-color: #F7941D;@link-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #DD1616;@font-size-base: 14px;@font-weight: 400;@heading-color: rgba(0, 0, 0, 1);@text-color: rgba(0, 0, 0, 1);@text-color-secondary: #5E5C62;@disabled-color: #AAAAAA;@border-radius-base: 5px;@border-color-base: #d9d9d9;@box-shadow-base: 0 2px 8px rgba(0, 0, 0, 0.15);