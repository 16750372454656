.tab-custom {
  .ant-tabs-nav {
    margin-bottom: 0px !important;
  }
  @media screen and (max-width: 576px) {
    padding: 0 10px;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--color-body);
    font-size: var(--font-small-body-bold-size);
    font-weight: var(--font-title-1-weight);
    text-shadow: none;
    a {
      color: inherit;
    }
  }

  .ant-tabs-ink-bar {
    height: 3px !important;
  }
  .ant-tabs-ink-bar::after {
    content: "";

    position: absolute;
    left: 50%;
    right: 0;
    bottom: 0;

    height: 3px;
    width: 100%;

    background: var(--bg-linear-gradient);

    transform: translateX(-50%);
  }

  .ant-tabs-tab {
    font-size: var(--font-body-bold-size);

    &:hover {
      color: var(--color-body);
    }
  }
  .ant-tabs-tab.ant-tabs-tab-disabled {
    color: var(--color-body);
    font-weight: 400;
  }
  .ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: 1px solid transparent !important;
  }
  .ant-tabs-nav {
    .ant-tabs-nav-wrap {
      width: 100%;
      .ant-tabs-nav-list {
        width: 100% !important;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations-hidden,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations-hidden {
    display: none;
    animation: none;
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations {
    display: none;
    animation: none;
  }
}

@primary-color: #F7941D;@link-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #DD1616;@font-size-base: 14px;@font-weight: 400;@heading-color: rgba(0, 0, 0, 1);@text-color: rgba(0, 0, 0, 1);@text-color-secondary: #5E5C62;@disabled-color: #AAAAAA;@border-radius-base: 5px;@border-color-base: #d9d9d9;@box-shadow-base: 0 2px 8px rgba(0, 0, 0, 0.15);