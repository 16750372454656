.floating-label {
  position: relative;
  display: flex;
  align-items: center;
  .input-loading {
    position: absolute;
    top: 50%;
    right: 0;
    svg {
      fill: var(--color-orange);
    }
  }
  .label-input {
    position: absolute;
    top: 20px !important;
    left: 0px;
  }
  &__eye {
    position: absolute;
    right: 0;
    top: 5px;

    padding-top: 17px;
    cursor: pointer;
  }

  .floating-input {
    border: none;
    width: 100%;
    height: 50px;
    display: block;
    border-radius: 0;
    padding: 17px 0 0;
    color: var(--color-body);
    background-color: transparent;
    font-size: var(--font-body-bold-size);
    border-bottom: 1px solid var(--bg-color-mono-8);
  }

  .floating-input:hover,
  .floating-input:focus {
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid var(--color-body);
  }

  .label {
    position: absolute;
    pointer-events: none;
    top: 20px;
    left: 2px;
    transition: 0.3s ease all;
    color: var(--bg-color-mono-2);
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
  }

  .floating-input:focus + .label {
    font-size: 12px;
    // transform: translateY(-75%);
  }

  *,
  *:before,
  *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  .label {
    left: 0;
    top: 20px !important;
    position: absolute;
    font-weight: normal;
    pointer-events: none;
    transition: 0.2s ease all;
  }

  .as-placeholder {
    color: var(--bg-color-mono-2);
    top: 20px !important;
  }

  .as-label {
    top: 20px;
    left: 0;
    padding: 0;
    font-size: var(--font-small-body-medium-size);
    position: absolute;
    pointer-events: none;
    transition: 0.3s ease all;
    color: #5e5c62;
    transform: translateY(-75%) translateX(-2px) !important;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
  }

  .ant-input:hover,
  .ant-input:focus,
  .ant-input-focused {
    border: 0;
    box-shadow: none !important;
    border-bottom: 1px solid var(--color-body);
  }
  .ant-input-status-error.floating-input {
    border-bottom: 2px solid var(--bg-color-mono-8);
    transition: all 0.1s;
  }
}
.ant-form-item-explain-error {
  font-size: 12px !important;
  padding-top: 5px;
}

@primary-color: #F7941D;@link-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #DD1616;@font-size-base: 14px;@font-weight: 400;@heading-color: rgba(0, 0, 0, 1);@text-color: rgba(0, 0, 0, 1);@text-color-secondary: #5E5C62;@disabled-color: #AAAAAA;@border-radius-base: 5px;@border-color-base: #d9d9d9;@box-shadow-base: 0 2px 8px rgba(0, 0, 0, 0.15);