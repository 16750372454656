.ant-radio-inner {
  border-color: var(--bg-color-mono-2) !important;
}
input[type="radio"]:checked ~ .ant-radio-inner::after {
  width: 4px;
  height: 4px;
  background-color: transparent !important;
}

input[type="radio"]:checked ~ .ant-radio-inner {
  border: 6px solid var(--bg-color-mono-1) !important;
}

.ant-radio-input:focus + .ant-radio-inner {
  box-shadow: none !important;
}
