.header-layout {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  padding: 0;
  width: 100%;
  box-shadow: 0px 1px 4px var(--bg-color-mono-4);
  background: var(--bg-color-mono-7);
  margin: 0 auto;

  &__container {
    max-width: 1280px;
    margin: 0 auto;

    .ant-row {
      height: 72px;
    }
  }

  .header-admin-conts {
    max-width: 786px;
    margin: 0 auto;
    // padding: 0 5px;
    color: var(--color-body);
    font-size: var(--font-small-body-regular-size);
    height: 72px;
    @media screen and(max-width: 1024px) {
      padding: 0 10px 0 10px;
    }
    .ant-typography {
      color: var(--color-body);
    }

    .ant-col {
      height: 100%;
    }

    .user-name {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      // margin-left: -5px;
      .breadcrumb {
        @media screen and (max-width: 992px) {
          display: none;
        }
        .ant-breadcrumb-separator {
          margin: 0 16px !important;
        }
        ol > li:first-of-type {
          display: flex;
        }
      }
      @media screen and (max-width: 576px) {
        display: none;
      }
    }

    .user-log {
      display: flex;
      text-align: right;
      align-items: center;
      justify-content: flex-end;
      padding-right: 10px;

      a {
        margin-left: 10px;
      }
    }
  }
}

.logo-admin {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 35px;
  div {
    padding-left: 5px;
  }
}
