.otp {
  display: flex;

  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 20px;
  &__input {
    position: relative;

    font-size: 20px !important;
    font-weight: 700;
    text-align: center;

    width: 50px !important;
    height: 50px !important;
    margin-left: 20px;
    @media screen and (max-width: 576px) {
      margin-left: 12px;
      width: 40px !important;
      height: 40px !important;
      font-size: 18px !important;
    }

    border-radius: 0 !important;
    box-shadow: none !important;
    border-top: 0 solid transparent !important;
    border-left: 0 solid transparent !important;
    border-right: 0 solid transparent !important;
    border-bottom: 2px solid var(--bg-color-mono-3) !important;

    &:focus,
    .ant-input-focused {
      box-shadow: none;
    }
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

@primary-color: #F7941D;@link-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #DD1616;@font-size-base: 14px;@font-weight: 400;@heading-color: rgba(0, 0, 0, 1);@text-color: rgba(0, 0, 0, 1);@text-color-secondary: #5E5C62;@disabled-color: #AAAAAA;@border-radius-base: 5px;@border-color-base: #d9d9d9;@box-shadow-base: 0 2px 8px rgba(0, 0, 0, 0.15);