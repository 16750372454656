.modal-confirm {
  &--custom {
    .modal__content {
      display: flex;
      flex-direction: row;
      // gap: 16px;
      padding-top: 32px;
      .ant-form-item-explain-connected {
        margin-bottom: 30px;
        margin-top: 5px;
      }
      .background-icon {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: none;
        &.success {
          &:extend(.background-icon);
          background-color: rgb(39 168 122 / 15%);
        }
        &.error {
          &:extend(.background-icon);
          background-color: rgb(253 193 193);
        }
      }
      .ant-typography {
        padding-left: 16px;
      }
    }
  }
}
