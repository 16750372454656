.my-vib-layout {
  background: #fff;
  font-size: var(--font-body-medium-size);
  display: flex;
  min-height: 100vh;
  .main {
    padding-top: 4.625rem;
    flex: 1;
    .ant-layout-content {
      width: 100%;
      max-width: 1110px;
      margin: 0 auto;
      @media screen and(max-width: 1024px) {
        padding: 10px;
      }
    }
  }

  .ant-breadcrumb-link {
    font-size: var(--font-body-semibold-size);
    font-weight: 400;
    color: var(--color-body);
    a {
      color: inherit !important;
    }
  }

  .footer {
    height: 72px;

    background-color: #fff;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 14px;
    color: var(--color-body);

    //  text-transform: capitalize;
    // box-shadow: 0px -8px 5px -5px var(--bg-color-mono-4);
    // -webkit-box-shadow: 0px -8px 5px -5px var(--bg-color-mono-4);
    // -moz-box-shadow: 0px -8px 5px -5px var(--bg-color-mono-4);
    .ant-layout-content {
      display: flex;
      padding: 0 10px 0 10px;
      width: 100%;
      height: 100%;
      max-width: 1110px;
    }

    &__main {
      border-top: 1px solid #e4e4e4;
      width: 100%;

      display: flex;
      align-items: center;
      justify-content: space-between;
      // gap: 10px;
    }
  }

  .footer__locale {
    @media screen and (max-width: 576px) {
      padding-left: 20px;
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border: none;
    }

    .ant-select-selector {
      padding-top: 5px;
    }

    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
      box-shadow: none !important;
    }
    .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
    .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
      padding-right: 0 !important;
    }
    .ant-select-selection-item {
      div {
        justify-content: flex-end !important;
      }
    }
  }
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.truncate--2 {
  -webkit-line-clamp: 2;
  word-break: break-all;
}

.truncate--3 {
  -webkit-line-clamp: 3;
  word-break: break-all;
}
